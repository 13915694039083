import { Component, Prop, Ref, Vue, Watch } from 'vue-property-decorator';
import { VueComponent } from '~/utils/vue-component';

import style from './NewsletterForm.scss';

import { MediaBackground, Link } from '~/components/molecules';
import imageHeroDefault from '~/assets/images/hero_default.jpg';
import { Headline, InputField, Button, CloseButton } from '~/components/atoms';
import { InputFieldState } from '~/components/atoms/inputField/InputField';
import { Align } from '~/components/atoms/headline/Headline';
import HtmlRenderer from '../../base/HtmlRenderer';
import { CztWidgets } from '~/utils/views/widgets';
import { PageLoader } from '..';
import { LocaleMessage } from 'vue-i18n';
import NewsletterModule, {
  getNewsletterKey,
  Newsletter,
} from '~/app/core/store/modules/NewsletterModule';
import NewsletterButton from '../../newsletter/NewsletterButton';
import { getModule } from 'vuex-module-decorators';
import LanguageModule from '~/app/core/store/modules/LanguageModule';
import setSubscribedToNewsletter from '~/utils/newsletter/setSubscribedToNewsletter';
import { getPathWithoutLocale } from '~/app/core/router';
import RecaptchaWrapper from '../../recaptchaWrapper/RecaptchaWrapper';
import { Target } from '~/utils/molecules/link';
import { NewsletterFormInterface } from './types';

const rootClass = 'czt-newsletter';

@Component({
  style,
})
export default class NewsletterForm
  extends VueComponent<NewsletterFormInterface>
  implements NewsletterFormInterface {
  @Prop()
  public anchorId?: string;

  @Prop({ required: true })
  public className!: CztWidgets;

  @Prop({ required: true })
  public guid!: string;

  @Prop({ type: String })
  public type?: string;

  @Prop({ default: false })
  public isBottomSpacingCollapsed!: boolean;

  @Prop({ default: false })
  public isTopSpacingCollapsed!: boolean;

  @Ref('newsletterInput')
  public readonly newsletterInput!: InputField;

  @Ref('newsletterForm')
  private readonly newsletterForm?: Vue;

  protected email: string = '';

  protected error: string | LocaleMessage = '';

  protected success: string | LocaleMessage = '';

  protected dialog: boolean = false;

  protected selectedNewsletters: string[] = [];

  protected ctaDialogActive: boolean = false;

  public get state(): InputFieldState {
    if (this.subscribing) {
      return InputFieldState.LOADING;
    }
    if (this.error !== '') {
      return InputFieldState.ERROR;
    }
    if (this.success !== '') {
      return InputFieldState.SUCCESS;
    }
    return InputFieldState.DEFAULT;
  }

  public get title(): LocaleMessage {
    if (this.success !== '' && this.$te('app.newsletter.successTitle')) {
      return this.$t('app.newsletter.successTitle');
    }
    switch (this.type) {
      case 'trade':
        return this.$te('app.newsletter.tradeTitle')
          ? this.$t('app.newsletter.tradeTitle')
          : this.$t('app.newsletter.title');
      case 'press':
        return this.$te('app.newsletter.pressTitle')
          ? this.$t('app.newsletter.pressTitle')
          : this.$t('app.newsletter.title');
      default:
        return this.$te('app.newsletter.b2bTitle')
          ? this.$t('app.newsletter.b2bTitle')
          : this.$t('app.newsletter.title');
    }
  }

  protected get topText(): LocaleMessage {
    switch (this.type) {
      case 'trade':
        return this.$te('app.newsletter.tradeTopText')
          ? this.$t('app.newsletter.tradeTopText')
          : this.$t('app.newsletter.topText');
      case 'press':
        return this.$te('app.newsletter.pressTopText')
          ? this.$t('app.newsletter.pressTopText')
          : this.$t('app.newsletter.topText');
      default:
        return this.$te('app.newsletter.b2bTopText')
          ? this.$t('app.newsletter.b2bTopText')
          : this.$t('app.newsletter.topText');
    }
  }

  protected get newsletterStore() {
    return getModule(NewsletterModule, this.$store);
  }

  protected get newslettersLoading() {
    return this.newsletterStore.loading;
  }

  protected get newsletters() {
    return this.newsletterStore.newsletters;
  }

  protected get subscribing() {
    return this.newsletterStore.subscribing;
  }

  protected get languageStore() {
    return getModule(LanguageModule, this.$store);
  }

  protected get currentLocale() {
    return this.languageStore.locale;
  }

  protected get newsletterKey(): string {
    return getNewsletterKey({
      codename: this.type,
      path: this.type ? getPathWithoutLocale(this.$router) : undefined,
    });
  }

  public mounted() {
    // Popup CTA-dialog on selected languages after 5 seconds
    if (window && this.currentLocale && this.currentLocale.oldCta) {
      setTimeout(() => {
        this.ctaDialogActive = true;
      }, 8 * 1000);
    }
  }

  public render() {
    const classes = ['czt-spacer'];

    if (this.isTopSpacingCollapsed) {
      classes.push('czt-spacer--collapse-top');
    }
    if (this.isBottomSpacingCollapsed) {
      classes.push('czt-spacer--collapse-bottom');
    }

    return (
      <MediaBackground
        id={this.anchorId}
        ref='newsletterForm'
        class={rootClass}
        image={{
          src: imageHeroDefault,
          alt: this.title,
        }}
        key={this.guid}
      >
        <v-container class={classes.join(' ')}>
          <v-fade-transition group hide-on-leave>
            {this.success === '' ? (
              <v-row key={`${rootClass}__email`}>
                <v-col cols='12'>
                  <Headline underscore level={2} light align={Align.CENTER}>
                    {this.title}
                  </Headline>
                </v-col>
                <v-col class='text-center' cols='12'>
                  <HtmlRenderer content={this.topText} light />
                </v-col>
                <v-col cols='12'>
                  <v-form
                    class={`${rootClass}__form`}
                    onSubmit={this.handleSubmit}
                  >
                    <v-row>
                      <v-col>
                        <InputField
                          errorMessage={this.error}
                          placeholder={this.$t(
                            'app.newsletter.inputPlaceholder'
                          )}
                          v-model={this.email}
                          state={this.state}
                          successMessage={this.success}
                          type='email'
                          ref='newsletterInput'
                          required
                        />
                      </v-col>
                      <v-col class='text-center' cols='12' md='auto'>
                        <Button
                          submit
                          loading={this.subscribing}
                          style='height: 56px !important'
                        >
                          {this.$t('app.newsletter.button')}
                        </Button>
                      </v-col>
                    </v-row>
                  </v-form>
                </v-col>
                <v-col class='text-center' cols='12'>
                  <HtmlRenderer
                    content={this.$t('app.newsletter.bottomText')}
                    light
                  />
                </v-col>
              </v-row>
            ) : (
              <v-row key={`${rootClass}__success`}>
                <v-col cols='12'>
                  <Headline underscore level={2} light align={Align.CENTER}>
                    {this.title}
                  </Headline>
                </v-col>
                <v-col class='text-center' cols='12'>
                  <HtmlRenderer content={this.success} light />
                </v-col>
              </v-row>
            )}
          </v-fade-transition>
        </v-container>
        <v-dialog content-class={`${rootClass}__dialog`} v-model={this.dialog}>
          <v-card class='light-blue lighten-5'>
            <v-container>
              <v-row>
                <v-col cols='12'>
                  <Headline underscore align={Align.CENTER}>
                    {this.$t('app.newsletter.dialog.headline')}
                  </Headline>
                </v-col>
                <v-col cols='12'>{this.createNewsletterList()}</v-col>
                <v-col class={`${rootClass}__dialog__notice`}>
                  <i18n tag='div' path='app.newsletter.dialog.notice'>
                    <Link
                      url={this.$t('app.url.privacy')}
                      target={Target.BLANK}
                    >
                      {this.$t('app.newsletter.dialog.link.privacy')}
                    </Link>
                  </i18n>
                  <i18n
                    class='mt-2'
                    tag='div'
                    path='app.newsletter.dialog.company'
                  >
                    <Link
                      url={this.$t('app.url.controller')}
                      target={Target.BLANK}
                    >
                      {this.$t('app.newsletter.dialog.link.controller')}
                    </Link>
                    <Link url={this.$t('app.url.web')} target={Target.BLANK}>
                      {this.$t('app.newsletter.dialog.link.web')}
                    </Link>
                  </i18n>
                </v-col>
                <v-col class='text-center' cols='auto'>
                  <RecaptchaWrapper
                    {...{
                      scopedSlots: {
                        default: (scope: any) => {
                          return (
                            <Button
                              disabled={this.selectedNewsletters.length < 1}
                              loading={this.subscribing}
                              {...{
                                on: scope.on,
                              }}
                            >
                              {this.$t('app.newsletter.button')}
                            </Button>
                          );
                        },
                      },
                    }}
                    onRecaptchaCallback={this.sendSubscription}
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-card>
        </v-dialog>
        {this.renderCtaDialog()}
      </MediaBackground>
    );
  }

  protected renderCtaDialog() {
    return (
      <v-dialog
        v-model={this.ctaDialogActive}
        content-class={`${rootClass}__cta-dialog`}
      >
        <v-card class='light-blue lighten-5'>
          <CloseButton
            onClose={() => {
              this.ctaDialogActive = false;
            }}
          />
          <v-container class='text-center'>
            <Headline level={4} align={Align.CENTER} class='mb-2'>
              {this.$t('app.newsletter.ctaDialogTitle')}
            </Headline>
            <p>{this.$t('app.newsletter.ctaDialogParagraph1')}</p>
            <p>{this.$t('app.newsletter.ctaDialogParagraph2')}</p>
            <p>{this.$t('app.newsletter.ctaDialogParagraph3')}</p>
            <Button
              onClick={() => {
                this.ctaDialogActive = false;
                if (
                  this.newsletterForm &&
                  this.newsletterForm.$el &&
                  this.newsletterForm.$el instanceof HTMLElement
                ) {
                  window.scrollTo({
                    top: this.newsletterForm.$el.offsetTop,
                    behavior: 'smooth',
                  });
                }
              }}
            >
              {this.$t('app.newsletter.ctaDialogBtn')}
            </Button>
          </v-container>
        </v-card>
      </v-dialog>
    );
  }

  protected handleSubmit(e: Event) {
    e.preventDefault();
    if (!this.newsletterInput.checkValidity()) {
      this.error = this.$t('app.newsletter.validityError');
      return;
    }
    this.error = '';
    this.openDialog();
  }

  protected openDialog() {
    this.newsletterStore.getNewsletters({
      codename: this.type,
      path: this.type ? getPathWithoutLocale(this.$router) : undefined,
    });
    this.dialog = true;
  }

  protected closeDialog() {
    this.dialog = false;
  }

  protected getNewsletters() {
    if (this.newsletters.length > 0) {
      return this.newsletters.map((newsletter) =>
        this.createNewsletterButton(newsletter)
      );
    } else {
      return;
    }
  }

  protected createNewsletterList() {
    if (this.newslettersLoading) {
      return <PageLoader />;
    }
    if (this.newsletters.length < 1) {
      return <div>{this.$t('app.newsletter.newsletterError')}</div>;
    } else {
      return (
        <v-item-group multiple v-model={this.selectedNewsletters}>
          <v-row no-gutters>{this.getNewsletters()}</v-row>
        </v-item-group>
      );
    }
  }

  protected createNewsletterButton(newsletter: Newsletter) {
    return (
      <v-col class='pa-1' cols='6' md='4' lg='3' key={newsletter.guid}>
        <NewsletterButton newsletter={newsletter} />
      </v-col>
    );
  }

  protected sendSubscription(token: string) {
    this.newsletterStore
      .subscribe({
        email: this.email,
        newsletters: this.selectedNewsletters,
        reCaptchaToken: token,
      })
      .then((response) => {
        if (response) {
          this.error = '';
          this.success = this.$t('app.newsletter.success');
          setSubscribedToNewsletter();
        } else {
          this.error = this.$t('app.newsletter.serverError');
        }
      })
      .catch(() => {
        this.error = this.$t('app.newsletter.serverError');
      })
      .finally(() => {
        this.closeDialog();
      });
  }

  @Watch('email')
  protected resetError() {
    this.error = '';
  }
}
